#guide {
  @apply text-gray-800 text-left m-auto;

  /* Basic styles */
  h1 {
    @apply font-bold text-6xl;
  }
  h2 {
    @apply text-3xl leading-6 mt-32 mb-8;
    &.no-emphasis {
      @apply text-2xl text-gray-500 mt-24
    }
  }
  h3 {
    @apply font-normal text-2xl mt-12 mb-6;
  }
  h4 {
    @apply font-semibold text-xl mb-1;
  }
  h5 {
    @apply font-semibold text-base mb-1;
  }
  p {
    @apply text-lg leading-relaxed mb-6;
    &.subhead {
      @apply text-3xl mt-12;
    }
    &.lead {
      @apply font-black text-xl mb-6
    }
    &.sm-line-height {
      @apply leading-none;
    }
  }
  ol {
    @apply text-lg my-6 list-decimal;
    li {
      @apply pb-4;
    }
  }
  a {
    text-decoration: underline;
  }
  /* Basic mobile styles */
  @media screen and (max-width: 480px) {
    h1 {
      @apply text-4xl;
    }
    h2 {
      @apply text-2xl leading-7 mt-32 mb-4;
      &.no-emphasis {
        @apply text-xl mt-16;
      }
    }
    h3 {
      @apply text-xl mt-16 mb-4;
    }
    h4 {
      @apply text-lg;
    }
    p {
      &.subhead {
        @apply text-xl mt-8;
      }
      &.lead {
        @apply text-lg mb-2;
      }
      &.sm-line-height {
        @apply leading-none;
      }
    }
    ol {
      @apply text-lg my-4;
    }
  }

  .img-responsive {
    @apply block max-w-full h-auto;
  }
  .alert {
    @apply mt-24 mb-16 p-12 relative bg-white border border-wiq-green text-gray-600 shadow-xl rounded-md;
    .tip {
      @apply absolute rounded-full bg-wiq-green text-white text-center shadow-sm flex items-center justify-center;
      width: 80px;
      height: 80px;
      top: -40px;
      left: -40px;
      .cta {
        @apply text-2xl font-normal tracking-wider uppercase;
        position: relative;
      }
    }

    &.alert-cta {
      @apply p-6;
    }
    &.alert-guide {
      @apply m-0;
      .btn-success {
        @apply font-bold text-sm leading-6 py-1.5 px-3 mt-2;
      }
      .error-text {
        @apply text-red-500 m-0;
      }
      .success-text {
        @apply my-1 mx-0 leading-4 text-blue-700;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .guide-footer {
    .alert {
      @apply mt-12 mb-0;
    }
  }
  table {
    @apply w-full my-12 mx-0 border border-gray-400 border-separate rounded-md shadow-xl;
    border-spacing: 0;
    th {
      @apply p-3;
    }
    tr,td {
      @apply p-3 border border-gray-100 border-separate;
    }
    tr:last-child td {
      @apply rounded-bl-md rounded-br-md;
    }
    tfoot {
      @apply text-gray-600 text-base;
      td {
        @apply pt-2 border-0;
      }
    }
  }
  .demo-screenshot {
    @apply mt-16 text-center rounded-md;
    img {
      @apply shadow-xl rounded-md border border-gray-300;
    }
    figcaption {
      @apply text-base text-gray-500 mt-3;
    }
  }
  ul {
    @apply text-lg;
  }
  .testimonial {
    @apply relative;
    &.green-quote {
      @apply bg-white;
      .quote-body {
        background-image: url("https://mktg-cdn.wrestlingiq.com/guides/green_rectangle.png");
        background-size: cover;
        background-repeat: no-repeat;
        &.small-quote {
          background-image: url('https://mktg-cdn.wrestlingiq.com/guides/green_small_bubble.png');
        }
      }
      .quote-mark {
        position: absolute;
        top: -5px;
        left: 60px;
        background-image: url('https://mktg-cdn.wrestlingiq.com/guides/green_quote_mark.png');
        background-size: cover;
        width: 42px;
        height: 25px;
      }
    }
    &.blue-quote {
      background-color: white;
      .quote-body {
        background-image: url('https://mktg-cdn.wrestlingiq.com/guides/blue_rectangle.png');
        background-size: cover;
        background-repeat: no-repeat;

        &.small-quote {
          background-image: url('https://mktg-cdn.wrestlingiq.com/guides/blue_small_bubble.png');
        }
      }
      .quote-mark {
        position: absolute;
        top: 10px;
        right: 100px;
        background-image: url('https://mktg-cdn.wrestlingiq.com/guides/blue_quote_mark.png');
        background-size: cover;
        width: 42px;
        height: 25px;
      }
    }
    .quote-body {
      padding: 64px;
    }

    p {
      @apply text-gray-800 text-base leading-7;
      &.author {
        @apply text-black font-semibold text-xl leading-8 mt-7 mb-0;
      }

      &.school {
        @apply font-semibold text-gray-500 text-base leading-7 uppercase -mt-2 pb-10;
      }
    }
    @media only screen and (min-width: 768px) {
      .lg-contain {
        background-size: contain !important;
      }
    }
  }
  .table-of-contents {
    h3 {
      @apply text-lg text-gray-600;
    }

    ol {
      @apply list-none text-base;
      counter-reset: reset;

      li {
        @apply pl-8 pb-1;
        &::before {
          @apply absolute text-base text-gray-700 font-semibold left-0;
          counter-increment: reset;
          content: counter(reset);
          font-family: "Inter", sans-serif;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }
}
