@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.transition-init {
    display: none;
}

.fa-secondary {
    opacity: 0.4;
}

.pricing-chip {
    @apply text-center w-full h-full flex items-center justify-center shadow cursor-pointer px-4 py-6 text-lg font-medium border border-gray-300 rounded-md text-gray-900 bg-white hover:border-wiq-green focus:outline-none focus:border-blue-200 focus:ring-blue-200 active:bg-gray-50 active:text-gray-500 transition duration-150 ease-in-out lg:text-xl;
}
